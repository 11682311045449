export const Widget = async () => {
    const mcMaksiK023Xyz = await fetch('https://api.maksik023.xyz/api/mc?ip=mc.maksik023.xyz&key=JMZ0weqJ2Bxm6dTe').then((response) => response.json());
    const localMaksiK023Xyz = await fetch('https://api.maksik023.xyz/api/mc?ip=local.maksik023.xyz&key=JMZ0weqJ2Bxm6dTe').then((response) => response.json());
    if (localMaksiK023Xyz.online) {
        if (mcMaksiK023Xyz.online) {
            document.getElementById('widget').src = 'https://api.mcstatus.io/v2/widget/java/mc.maksik023.xyz';
        } else {
            document.getElementById('widget').src = 'https://api.mcstatus.io/v2/widget/java/local.maksik023.xyz';
        }
    } else {
        document.getElementById('widget').src = 'https://api.mcstatus.io/v2/widget/java/mc.maksik023.xyz';
    }
}