const ErrorPage = () => {
    window.location.pathname = '/';
    // return (
    //     <>
    //         <center>
    //             <h1 style={{ color: 'red' }}>Error 404!</h1>
    //             <h3>Page not found!</h3>
    //         </center>
    //     </>
    // )
}

export default ErrorPage;
