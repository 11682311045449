const OnlyPcPage = () => {
    return (
        <>
            <center>
                <h1 style={{ color: 'red' }}>Error 404!</h1>
                <h3>The website is only available for computers!</h3>
            </center>
        </>
    )
}

export default OnlyPcPage;
