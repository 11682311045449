const GradePage = () => {
    // window.location.href = 'https://oceny.maksik023.xyz/';
    return (
        <>
            <center>
                <h1 style={{ color: 'red' }}>Error 404!</h1>
                <h3>Strona jest podczas prac technicznych!</h3>
            </center>
        </>
    )
}

export default GradePage;
