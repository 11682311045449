import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './assets/css/style.css';

import { IpLogger } from './functions/ipLogger';
import Home from './pages/Home';
import ErrorPage from './pages/ErrorPage';
import MediaDc from './pages/media/Discord';
import MediaTwitch from './pages/media/Twitch';
import MediaCs from './pages/media/Cs';
import OnlyPcPage from './pages/OnlyPc';
import ToolsGrade from './pages/GradeCalculator';

export default function App() {
  // IpLogger();
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path='/' element={<Layout />}> */}
        <Route>
          <Route index element={<Home />} />
          <Route path='/media/dc' element={<MediaDc />} />
          <Route path='/media/twitch' element={<MediaTwitch />} />
          <Route path='/media/cs' element={<MediaCs />} />
          <Route path='/onlypc' element={<OnlyPcPage />} />
          <Route path='/tools/oceny' element={<ToolsGrade />} />
          <Route path='*' element={<ErrorPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);