import logo from '../assets/images/logo.png';
import { Timer } from '../functions/serverTimer';
import { Widget } from '../functions/widget';
import { MobileBlocker } from '../functions/onlyPc';

const Home = () => {
    MobileBlocker();
    // Timer();
    Widget();
    return (
        <>
            <header>
                <div id='header-left'>
                    <a href='/'><img src={logo} className='left header logo' style={{ marginLeft: '5px' }} alt='Logo.png'></img></a>
                    <h4 className='left header header-text'><a href='/'>maksik023.xyz</a></h4>
                </div>
                <div id='header-right'>
                    <h4 className='right header header-text'><a href='/'>Strona główna</a></h4>
                    <h4 className='right header header-text'><a href='/projekty'>Projekty</a></h4>
                    <h4 className='right header header-text'><a href='/media'>Media</a></h4>
                    <h4 className='right header header-text'><a href='/opinie'>Opinie</a></h4>
                    <h4 className='right header header-text'><a href='/cennik'>Cennik</a></h4>
                    <h4 className='right header header-text'><a href='/portfolio'>Portfolio</a></h4>
                    <a href='/media/dc' target='_blank'><button className='right header header-button'>Discord</button></a>
                    <a href='/dashboard'><button className='right header header-button'
                        style={{ marginRight: '5px' }}>Dashboard</button></a>
                </div>
            </header>
            <div id='web'>
                <a href='/'><img src={logo} style={{ borderRadius: '50px', width: '250px' }} alt='Logo.png'></img></a>
                <h1>Cześć <span className='wave'>👋</span>, jestem MaksiK023</h1>
                {/* <h3>mc.maksik023.xyz: <span id='countdown' style={{ color: 'grey' }}></span></h3> */}
                <img src='' id='widget' alt='Minecraft Server Widget' style={{ width: '500px', borderRadius: '10px' }}></img>
            </div>
        </>
    )
}

export default Home;
